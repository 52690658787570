<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formSendEmailTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSend()"
        >
          <v-row>
            <v-col cols="11" class="py-0">
              <v-autocomplete
                outlined
                clearable
                dense
                :items="formatosItems"
                item-text="nombre"
                item-value="emailId"
                autocomplete="on"
                label="Formatos de Email"
                v-model="formatoSelected"
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
export default {
  name: "EnviarOrdenesPagoEmail",
  props: {
    registrosSeleccionados: {
      type: Array,
      required: false,
    },
    idEntidadFacturante: {
      type: Number,
      required: false,
    },
    movProvIdOp: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    isFormValid: false,
    formSendEmailTitle: "Selección de formato para envío de email",
    rules: rules,
    formatosItems: [],
    formatoSelected: null,
    formatosEmailFounded: null,
    codigo: "OPRET",
  }),
  created() {
    this.setSelects();
  },
  methods: {
    ...mapActions({
      fetchFormatosEmail: "configuracion/fetchFormatosEmail",
      crearProcesoEnvioMasivoOP: "proveedores/crearProcesoEnvioMasivoOP",
      envioEmailIndividualOP: "proveedores/envioEmailIndividualOP",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      this.formatosEmailFounded = await this.fetchFormatosEmail(this.codigo);
      if (this.registrosSeleccionados) {
        //Entra si se abrio desde ordenes de pago
        //Selecciono el id de la entidad del primer item para encontrar formatos de esa empresa
        const idEntFact = this.registrosSeleccionados[0].entFacIdXTComp;
        const formatosObj = this.formatosEmailFounded.filter(
          (obj) => obj.entFacId == idEntFact || obj.entFacId == null
        );
        const formatosActivos = formatosObj.filter((el) => el.activo == true);
        if (formatosActivos.length > 1) {
          this.formatosItems = formatosActivos;
        } else {
          this.formatosItems = formatosActivos;
          this.formatoSelected = formatosActivos[0].emailId;
        }
        this.formatosItems = formatosActivos;
      } else if (this.idEntidadFacturante) {
        //Entra si se abrio desde ver orden de pago especifico
        const formatosObj = this.formatosEmailFounded.filter(
          (obj) =>
            obj.entFacId == this.idEntidadFacturante || obj.entFacId == null
        );
        const formatosActivos = formatosObj.filter((el) => el.activo == true);
        if (formatosActivos.length > 1) {
          this.formatosItems = formatosActivos;
        } else {
          this.formatosItems = formatosActivos;
          this.formatoSelected = formatosActivos[0].emailId;
        }
      }
    },
    async canSend() {
      this.isFormValid = false;
      if (this.registrosSeleccionados) {
        const data = {
          listMProvId: this.registrosSeleccionados.map((x) => x.movProvId),
          emailId: this.formatoSelected,
        };
        const res = await this.crearProcesoEnvioMasivoOP(data);
        if (res.status === 200) {
          this.setAlert({
            type: "success",
            message: "Proceso masivo generado con éxito",
          });
          this.closeModal();
          this.isFormValid = true;
        }
      } else {
        const data = {
          codOperacion: "OPRET",
          tablaId: this.movProvIdOp,
          emailId: this.formatoSelected,
        };
        const res = await this.envioEmailIndividualOP(data);
        if (res.status === 200) {
          this.setAlert({
            type: "success",
            message: "Email enviado con éxito",
          });
          this.closeModal();
          this.isFormValid = true;
        } else {
          this.isFormValid = true;
        }
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>